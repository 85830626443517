import { VisibilityOff, Visibility } from "@mui/icons-material";
import { Grid, InputAdornment, IconButton } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BusinessInputs } from "../../../../interfaces/types/User";
import { InputField } from "../../InputField/InputField";
import { ImportantInfoWrapper } from "../ImportantInformation/styles";

export default function BusinessImportantInformation() {
  const {
    register,
    formState: {
      errors: { vat, address, email, password, phoneNumber, postalCode },
    },
  } = useFormContext<BusinessInputs>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const { t } = useTranslation();
  return (
    <>
      <ImportantInfoWrapper>
        {t("register.important_information")}
      </ImportantInfoWrapper>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6}>
          <InputField
            label={t("register.email")}
            type="input"
            fullWidth
            size="small"
            {...register("email")}
            fieldError={email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            label={t("register.address")}
            type="input"
            fullWidth
            size="small"
            {...register("address")}
            fieldError={address}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            label={t("editProfile.postal_code")}
            type="input"
            fullWidth
            size="small"
            {...register("postalCode")}
            fieldError={postalCode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            label={t("register.business.vat")}
            type="string"
            fullWidth
            size="small"
            {...register("vat")}
            fieldError={vat}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            label={t("register.password")}
            type={showPassword ? "text" : "password"}
            fullWidth
            size="small"
            {...register("password")}
            fieldError={password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            label={t("register.phone_number")}
            type="input"
            fullWidth
            size="small"
            {...register("phoneNumber")}
            fieldError={phoneNumber}
          />
        </Grid>
      </Grid>
    </>
  );
}
