import { Container } from "@mui/material";
import {
  Avatar,
  Icon,
  MeetTeamWrapper,
  Name,
  Position,
  TeamMember,
} from "./styles";
import meet_icon from "../../../../assets/images/meet_team.png";
import ErwinImage from "../../../../assets/images/team/Erwin.jpg";
import JeanImage from "../../../../assets/images/team/Jean.jpg";
import TomImage from "../../../../assets/images/team/Tom.jpg";
import JulienImage from "../../../../assets/images/team/Julien_Zingraff.jpg";
import AurelienImage from "../../../../assets/images/team/Aurelien.jpg";
import AlissonImage from "../../../../assets/images/team/Alisson.jpeg";

export default function MeetTeam() {
  const team = [
    {
      id: 1,
      image: ErwinImage,
      name: "Erwin Schellens",
      position: "Managing Founder",
    },
    {
      id: 2,
      image: JeanImage,
      name: "Jean-François Van Humbeeck",
      position: "Managing Founder",
    },
    {
      id: 3,
      image: JulienImage,
      name: "Julien Zingraff",
      position: "Managing Founder",
    },
  ];
  const team2 = [
    {
      id: 1,
      image: AlissonImage,
      name: "Alisson Ardizzone",
      position: "Ambassador",
    },
    {
      id: 2,
      image: AurelienImage,
      name: "Aurélien Verschae",
      position: "Ambassador",
    },
    {
      id: 3,
      image: TomImage,
      name: "Tom Abergel",
      position: "Digital Marketing Specialist",
    },
  ];

  return (
    <MeetTeamWrapper>
      <Container>
        <Icon src={meet_icon} />
        <div className="flex flex-col justify-between lg:flex-row gap-2 items-center ">
          {team.map((member) => (
            <TeamMember key={member.id}>
              <Avatar src={member.image} />
              <Name>{member.name}</Name>
              <Position>{member.position}</Position>
            </TeamMember>
          ))}
        </div>
        <div className="flex flex-col justify-evenly lg:flex-row gap-2 items-center">
          {team2.map((member) => (
            <TeamMember key={member.id}>
              <Avatar src={member.image} />
              <Name>{member.name}</Name>
              <Position>{member.position}</Position>
            </TeamMember>
          ))}
        </div>
      </Container>
    </MeetTeamWrapper>
  );
}
